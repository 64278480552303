<svelte:options tag="fds-tab-content"></svelte:options>
<script>
    import { onMount} from 'svelte';
    import {get_current_component} from "svelte/internal";
    import { writable } from 'svelte/store'

    let host = get_current_component()
    function getContext(name,element) {
        let other= element.closest(name)
        if (!other) return false
        return other.fds_context
    }
    const panel = {};
    let registerPanel, selectedPanel
    selectedPanel=writable(null);
    registerPanel=writable(null);
    onMount(() => {
        let ctx = getContext("fds-tabs",host);
        registerPanel=ctx.registerPanel
        selectedPanel=ctx.selectedPanel
        registerPanel(panel);
    })
</script>

{#if $selectedPanel === panel}
    <slot></slot>
{/if}