<svelte:options tag="fds-tab"></svelte:options><script>
    import {get_current_component} from "svelte/internal"
    let host = get_current_component()
    import { onMount } from "svelte";
    import {writable} from "svelte/store";

    const tab = {};
    function getContext(name,element) {
        let other= element.closest(name)
        if (!other) return false
        return other.fds_context
    }
    let registerTab, selectTab, selectedTab

    selectedTab=writable(null)
    selectTab=writable(null)
    onMount(() => {
        let ctx = getContext("fds-tabs",host);
        host.style.position="relative"
        registerTab=ctx.registerTab
        registerTab(tab);
        selectTab=ctx.selectTab
        selectedTab=ctx.selectedTab
    })

</script><style>

    * {
        box-sizing: border-box;
    }
    div {
        background: none;
        border: none;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        display: inline-block;
        margin: 0;
        color: #555;
        cursor:pointer;
        margin-right: 8px;
    }

    .selected {
        border-bottom: 2px solid #36c6f3;
        color: #333;
    }
</style>
<div class:selected="{$selectedTab === tab}" on:click="{() => selectTab(tab)}">
    <slot></slot>
</div>