<svelte:options tag="fds-tabs"></svelte:options>
<script>
     import { afterUpdate, onDestroy, onMount, tick } from 'svelte'
     import { writable } from 'svelte/store'
     import {get_current_component} from "svelte/internal"
     import fdsHelper from "@fds-components/fds-helper"
     import {name, version as componentversion} from '../package.json';
     const path = fdsHelper.get_href()
     let host = get_current_component()
     /**
      * selected tab index
      * @type {number}
      */
     export let selected_tab_index = 0;

     const tabElements = [];
     const tabs = [];
     const panels = [];
     const controls = writable({});
     const labeledBy = writable({});
     const selectedTab = writable(null);
     const selectedPanel = writable(null);

     /**
      * Get information about component
      * @param  {("api" | "examples" | "css")} type the info type
      */
     export async function getInfo(type) {
         if (type==="version"){
             return new Promise(resolve => {
                 resolve(componentversion);
             });
         }
         let res = await fdsHelper.getInfo(type,name);
         return res;
     }

     /**
      * version of component
      * @type {string}
      */
     export const version = componentversion;

     function setContext(obj) {
         host.fds_context=obj
     }
     function removeAndUpdateSelected(arr, item, selectedStore) {
         const index = arr.indexOf(item);
         arr.splice(index, 1);
         selectedStore.update(selected => selected === item ? (arr[index] || arr[arr.length - 1]) : selected);
     }
     function registerItem(arr, item, selectedStore) {
         arr.push(item);
         selectedStore.update(selected => selected || item);
     }
     function selectTab(tab) {
         if (tabs.indexOf(tab)<0) return
         selected_tab_index = tabs.indexOf(tab);
         selectedTab.set(tab);
         selectedPanel.set(panels[selected_tab_index]);
     }


     onMount(() => {
         setContext({
             registerTab(tab) {
                 registerItem(tabs, tab, selectedTab);
                 if (selected_tab_index && tabs[selected_tab_index] && panels[selected_tab_index]){
                     selectTab(tabs[selected_tab_index]);
                 }

             },
             registerTabElement(tabElement) {
                 tabElements.push(tabElement);
             },
             registerPanel(panel) {
                 registerItem(panels, panel, selectedPanel);
             },
             selectTab,
             selectedTab,
             selectedPanel,
             controls,
             labeledBy
         });
         selected_tab_index=parseInt(selected_tab_index)
         if (tabs)  {
             selectTab(tabs[selected_tab_index]);
         }
     });
     afterUpdate(() => {
         for (let i = 0; i < tabs.length; i++) {
             controls.update(controlsData => ({...controlsData, [tabs[i].id]: panels[i].id}));
             labeledBy.update(labeledByData => ({...labeledByData, [panels[i].id]: tabs[i].id}));
         }

     });
     $: selected_tab_index, ()=>{
         selectedTab.set(tabs[selected_tab_index]);
        // selectedPanel.set(panels[selected_tab_index]);
     };
     $: if(selected_tab_index){
         setTimeout(() => {
             selectTab(tabs[selected_tab_index]);
             selectedPanel.set(panels[selected_tab_index]);
         }, 10);

     }
</script>
<style>
    .tab-list {
/*        border-bottom: 1px solid teal;*/
    }
</style>
<div class="svelte-tabs" >
    <div class="tab-list">
        <slot name="tabs" />
    </div>
    <slot name="content" />
</div>
